import * as React from "react";
import Seo from "./../../components/Seo";
import Title from "./../../components/Title";

// const minFontSize = 12;
// const maxFontSize = 16;
// const minWidth = 480;
// const maxWidth = 1200;

// const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
// const yAxisIntersection = -minWidth * slope + minFontSize;
// preferredValue = yAxisIntersection[rem] + (slope * 100)[vw];

const ClampCalculator = () => {
  const [minFontSize, setMinFontSize] = React.useState(12);
  const [maxFontSize, setMaxFontSize] = React.useState(16);
  const [minWidth, setMinWidth] = React.useState(480);
  const [maxWidth, setMaxWidth] = React.useState(1200);
  const [rem, setRem] = React.useState();
  const [vw, setVw] = React.useState();

  const toVW = (num) => `${(num * 100).toFixed(3)}vw`;
  const toREM = (px) => `${(px / 16).toFixed(3)}rem`;

  React.useEffect(() => {
    const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
    const yAxisIntersection = -minWidth * slope + minFontSize;
    setRem(toREM(yAxisIntersection));
    setVw(toVW(slope));
  }, [minFontSize, maxFontSize, minWidth, maxWidth]);
  return (
    <>
      <Seo title="Clamp Calculator" />;
      <section
        id="clamp-calculator"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Title text="Clamp Calculator" />
        <p>Min Font Size</p>
        <input
          type="number"
          value={minFontSize}
          onChange={({ target }) => {
            setMinFontSize(target.value);
          }}
        />
        <p>Max Font Size</p>
        <input
          type="number"
          value={maxFontSize}
          onChange={({ target }) => setMaxFontSize(target.value)}
        />
        <p>Min Width</p>
        <input
          type="number"
          value={minWidth}
          onChange={({ target }) => setMinWidth(target.value)}
        />
        <p>Max Width</p>
        <input
          type="number"
          value={maxWidth}
          onChange={({ target }) => setMaxWidth(target.value)}
        />
        <p>
          Use: clamp({toREM(minFontSize)}, {rem} + {vw}, {toREM(maxFontSize)})
        </p>
      </section>
    </>
  );
};

export default ClampCalculator;
